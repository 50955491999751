body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

.ft-screen {
  max-width: 980px;
}

div.r1-innerpadding {
  display: flex;
  justify-content: center;
}

#tippspiel span.tournamentSelectionComponent button {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  color: #FB8501;
  letter-spacing: 0.07px;
  text-decoration: none;
}

#tippspiel span.tournamentSelectionComponent div button {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #FB8501;
  letter-spacing: 0.07px;
}

.table thead th {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 0.75rem;
  color: #000000;
  letter-spacing: 0;
}

.table {
  max-width: 980rem;
}

.ft-team-link, .ft-team-link-sm, .ft-team-link-lg {
  max-width: 85px;
  width: auto;
  letter-spacing: 0.06px;
  padding-top: 0.25rem
}
.ft-team-link-sm, .ft-team-link-lg {
  max-width: 180px;
}

#tippspiel .btn-r1gray {
  background-color: #F2F2F2;
  border: 1px solid #CECECE;
  border-radius: 4px;
  color: black;
}

.btn-link {
  color: #0066cc;
  text-decoration: none;
}

.btn-link:hover {
  color: #0066cc;
  text-decoration: underline;
}

a {
  color: #06c;
  text-decoration: none;
}

a:hover {
  color: #06c;
  text-decoration: underline;
}

.btn-r1--gameresult {
  font-family: 'Open Sans', sans-serif;
  color: #FFFFFF;
  letter-spacing: 0.06px;
  text-align: center;
  background: #3C3C3C;
  border-radius: 4px;
  padding: 0.25rem 0.25rem;
  display: inline-block;
  min-width: 35px;
}

.btn-r1--bet {
  font-family: 'Open Sans', sans-serif;
  color: #FFFFFF;
  letter-spacing: 0.06px;
  text-align: center;
  background: #46C746;
  border-radius: 4px;
  padding: 0.25rem 0.25rem;
  display: inline-block;
  min-width: 35px;
}

.btn-r1--bet-input {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.9rem;
  color: #FFFFFF;
  letter-spacing: 0.06px;
  text-align: center;
  background: #E2FCE2;
  box-shadow: inset 1px 1px 4px 0 rgba(8,94,8,0.80);
  border-radius: 4px;
  padding: 0.05rem 0.05rem;
  display: inline-block;
  min-width: 33px;
  min-height: 30px;
}

.btn-r1--bet-input Input {
  margin-top: 4px;
  background-color: #E2FCE2;
  width: 14px;
  max-height: 22px;
}

.btn-r1--points {
  font-family: 'Open Sans', sans-serif;
  color: #000000;
  letter-spacing: 0.06px;
  text-align: center;
  background-image: linear-gradient(180deg, #FFFFFF 30%, #F2F2F2 100%);
  border: 1px solid #CECECE;
  border-radius: 4px;
  padding: 0.25rem 0.45rem;
  display: inline-block;
  min-width: 28px;
}
